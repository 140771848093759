import { BigText, Paragraph } from "../atoms";
const RegistrationForm = () => {
    return (
        <div className="shadow-lg p-10 mt-10">
            <BigText text="Reservation" color="#AA8B55" className="mb-8" />
            <div className="mt-4 mb-8">
                <Paragraph text="Ask The Experts with Matthew Barrett" className="text-lg" />
                <Paragraph text="Tue, Feb 23, 2021 12:30 PM - 1:30 PM CST" />
            </div>
            <div className="mt-2 mb-6">
                <div className="flex justify-between items-center mb-16">
                    <div>
                    <Paragraph text={<p>General Admission <br /> Free</p>} className="text-lg" />
                    </div>
                    <div className="flex items-center">
                        <p className="mr-2">Qty:</p>
                        <select name="number" className="border-gray-300" id="" >    <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-8">
                    <input type="text" className="placeholder-gray-300 border-gray-300" placeholder="First Name"/>
                    <input type="text" className="placeholder-gray-300 border-gray-300" placeholder="Last Name"/>
                    <input type="email" className="placeholder-gray-300 border-gray-300" placeholder="Email"/>
                    <input type="tel" className="placeholder-gray-300 border-gray-300" placeholder="Phone Number"/>
                </div>
                <div className="flex justify-end mt-6">
                    <input type="button" className="bg-brown text-white px-10 py-4 pointer" value="Register"/>
                </div>
            </div>
        </div>
    );
}

export { RegistrationForm }