import React from "react";
import { Paragraph } from "../atoms";
import { SectionWrapper } from "../layout/SectionWrapper";

const Doctrine = () => {
  return (
    <div>
      <SectionWrapper>
        <Paragraph
          text="1. We believe the Bible to be the divinely inspired, infallible, inerrant, and authoritative Word of God."
          color="3F4648"
          className="text-base"
        />
        <Paragraph
          text="2. We believe there is one God, eternally existent in three persons: God the Father, God the Son, and God the Holy Spirit."
          color="3F4648"
          className="text-base py-4"
        />
        <Paragraph
          text="3. We believe in the virgin birth of the Lord Jesus Christ, His deity, His sinless humanity and perfect life, the eternal all-sufficiency of His atoning death, His bodily resurrection, His ascension to the Father’s right hand, and His personal imminent return to this Earth in power and great glory."
          color="3F4648"
          className="text-base py-4"
        />
        <Paragraph
          text="4. We believe that justification is a judicial act of God on the believer’s behalf solely on the merits, blood, and righteousness of Jesus Christ, and that regeneration (the new birth) by the power of the Holy Spirit is absolutely essential for personal salvation. This salvation is by grace alone through faith alone."
          color="3F4648"
          className="text-base py-4"
        />
        <Paragraph
          text="5. We believe in the Lordship of Christ over His church; the observance of the ordinances of baptism by immersion for believers and the Lord’s Supper."
          color="3F4648"
          className="text-base py-4"
        />
        <Paragraph
          text="6. We believe Jesus Christ is coming again the second time to receive His own to himself who will be with Him for eternity. We believe the second coming of Christ is literal, personal, powerful, and bodily. The second coming of Christ does not take place in stages or phases. Christ will return to receive those who have been born again and judge those who have rejected His rule and reign over their lives. Those who are declared unrighteous will spend an eternity separated from God."
          color="3F4648"
          className="text-base py-4"
        />
        <Paragraph
          text="7. We believe in a literal heaven and a literal hell. We believe heaven is the abode of all that receive Christ as Lord and Saviour. We believe hell is the abode of all that reject Christ as Lord and Saviour. We further believe hell is a literal place where God and good do not exist and there exits in hell an eternal fire."
          color="3F4648"
          className="text-base py-4"
        />
        <Paragraph
          text="8. We believe there is a spiritual relationship of all believers in the Lord Jesus Christ so that all Christians must live a life of good works separated from the world in order to be an effective witness to the Lord’s saving grace through the power and personal ministry of the Holy Spirit."
          color="3F4648"
          className="text-base py-4"
        />
        <Paragraph
          text="An expanded statement of the doctrinal position of GTS is set forth the in Baptist Confession of 1689. A free printed copy of the confession will be provided each student upon request."
          color="3F4648"
          className="text-base py-4"
        />
        <Paragraph
          text="An expanded statement of the doctrinal position of GTS is set forth the in Baptist Confession of 1689. A free printed copy of the confession will be provided each student upon request."
          color="3F4648"
          className="text-base py-4"
        />
        <Paragraph
          text="Nevertheless, the seminary is open to students without an express commitment to this confession, so long as the student declares in good faith teachable spirit and understands that the instruction of the seminary will always be Reformed in its convictions and expressions. It is our commitment that the seminary will freely offer instruction to any who are of willing mind and heart to “search the scriptures”. Therefore students who are not in essential agreement with the confession of the seminary may be accepted on a case by case basis by the permission of the board of the seminary and shall remain student by the pleasure of the same."
          color="3F4648"
          className="text-base py-4"
        />
      </SectionWrapper>
    </div>
  );
};

export { Doctrine };
