const SectionWrapper = ({ children, className }) => {
  return (
    <section
      className={`container pt-10 pb-10 m-auto w-11/12 lg:w-11/12 xl:pt-28 xl:pb-16 lg:pt-20 lg:pb-10 md:pt-16 md:pb-8 relative ${className}`}
    >
      {children}
    </section>
  );
};

export { SectionWrapper };
