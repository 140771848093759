const events = [
    {
        id: 1,
        title: "Tea Time With Spurgeon",
        dateAndTime: "February 11 @ 3:00pm - 3:45pm"
    },
    {
        id: 2,
        title: "Tea Time With Spurgeon",
        dateAndTime: "February 11 @ 3:00pm - 3:45pm"
    },
    {
        id: 3,
        title: "Tea Time With Spurgeon",
        dateAndTime: "February 11 @ 3:00pm - 3:45pm"
    }
]

export default events