import { Link } from "react-router-dom";

import { SectionWrapper } from "./SectionWrapper";
import logo from "../assets/gts-logo.png";
import location from "../assets/location.svg";
import phone from "../assets/call-24px.svg";
import email from "../assets/email-24px.svg";
import rightArrow from "../assets/right-arrow.svg";
import arrowUp from "../assets/arrow-up.svg";

function Footer() {
  const scrollToTop = () => {
    document.getElementById("header").scrollIntoView({ behavior: "smooth" });
  };
  return (
    <footer>
      <div className="main-footer" style={{ backgroundColor: "#1D2122" }}>
        <SectionWrapper>
          <div
            className="news-letter p-4 md:p-10 md:grid md:grid-cols-2 md:gap-10 md:justify-between md:items-center"
            style={{ backgroundColor: "#282C2E" }}
          >
            <div className="flex lg:col-span-1 w-full">
              <input
                className="md:p-5 border-0 w-full"
                type="text"
                placeholder="Subscribe To Our Newsletter"
              />
              <button className="bg-brown text-white p-5">Subscribe</button>
            </div>
            <div className="p-4 md:p-0">
              <div>
                <p className="text-lg text-white font-semibold my-4 md:my-0">
                  Sign Up Newsletter
                </p>
                <p className="text-grayish mb-4 md:my-0">
                  Sign up for our newsletter to receive news about GTS
                </p>
                <a className="text-brown flex" href="/">
                  About us <img src={rightArrow} alt="" />{" "}
                </a>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10 justify-center mt-12">
            <div>
              <div className="flex justify-center md:justify-start">
                <img className="md:ml-3 py-2 h-24" src={logo} alt="" />
              </div>
              <ul className="text-grayish">
                <li className="py-1 flex items-start">
                  <img className="mr-4 w-4 mt-1" src={location} alt="" />
                  <a href="/">
                    451a, Damilola Fashade Street, Omole Phase 1, Ojodu Berger,
                    Lagos.{" "}
                  </a>
                </li>
                <li className="py-1 flex">
                  <img className="mr-4 w-4" src={phone} alt="" />
                  <a href="/">+234 803 262 2079</a>
                </li>
                <li className="py-1 flex">
                  <img className="mr-4 w-4" src={email} alt="" />
                  <a href="mailto:info@gtslagos.ng">info@gtslagos.ng</a>
                </li>
                <li className="py-1 flex">
                  <a href="https://www.gtslagos.ng">www.gtslagos.ng</a>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="text-white mb:1 md:mb-4 text-lg font-semibold">
                Explore
              </h5>
              <ul className="text-grayish">
                <Link onClick={scrollToTop} to="/academic/degree">
                  <li className="py-2">Academics</li>
                </Link>
                <Link to="/admission/Admission policy" onClick={scrollToTop}>
                  <li className="py-1">Admission</li>
                </Link>

                <Link to="/Grace Campus Outreach" onClick={scrollToTop}>
                  <li className="py-1">Outreach</li>
                </Link>
                <Link to="/partnership" onClick={scrollToTop}>
                  <li className="py-1">Partnership</li>
                </Link>

                <Link to="/Organizational Structure" onClick={scrollToTop}>
                  <li className="py-1">Organisational Structure</li>
                </Link>
              </ul>
            </div>
            <div>
              <h5 className="text-white mb-1 md:mb-4 text-lg font-semibold">
                Learn
              </h5>
              <ul className="text-grayish">
                <li className="py-1">
                  <a href="/academic/degree/#degreesAndCurriculum">Certificate of Ministry</a>
                </li>
                <li className="py-1">
                  <a href="/academic/degree/#degreesAndCurriculum">Associate Degree of Ministry</a>
                </li>
                <li className="py-1">
                  <a href="/academic/degree/#degreesAndCurriculum">Bachelor's Degree of Theology</a>
                </li>
                <li className="py-1">
                  <a href="/academic/degree/#degreesAndCurriculum">Bachelor's Degree of Ministry</a>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="text-white mb-1 md:mb-4 text-lg font-semibold">
                Connect with us
              </h5>
              <ul className="text-grayish">
                <li className="py-1">
                  <a href="https://www.facebook.com/GTSeminary" target="_blank" rel="noreferrer">Facebook</a>
                </li>
                <li className="py-1">
                  <a href="https://www.instagram.com/GTSeminary/" target="_blank" rel="noreferrer">Instagram</a>
                </li>
                <li className="py-1">
                  <a href="https://www.youtube.com/channel/UCoL7aikB4CXrKg7ooTXwi1Q" target="_blank" rel="noreferrer">Youtube</a>
                </li>
              </ul>
            </div>
          </div>
        </SectionWrapper>
        {/* Scroll to top */}
        <div className="flex justify-end pr-10 pb-10" onClick={scrollToTop}>
          <div className="bg-brown w-10 h-10 flex items-center justify-center">
            <img src={arrowUp} alt="" />
          </div>
        </div>

        {/* Bottom */}
        <div className="bg-brown p-4 text-blue">
          <div className="md:mx-32 lg:mx-48 xl:mx-80 flex flex-col md:flex-row items-center justify-center md:justify-between text-center">
            <p className="text-sm mb-4 md:mb-0">
              Copyright GTS. All Rights Reserved.{" "}
            </p>
            <div className="mb-4 md:mb-0">
              <a href="/" className="px-2">
                Privacy
              </a>
              <a href="/" className="px-2">
                Terms
              </a>
              <a href="/" className="px-2">
                Cookie Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
