import React from "react";
import { Link } from "react-router-dom";

import { BigText, Paragraph, SectionHeaderText } from "../atoms";
import { SectionWrapper } from "../layout/SectionWrapper";
import arrowRight from "./../assets/arrowRight.svg";

const Support = () => {
  return (
    <div>
      <SectionWrapper>
        <div className="text-center text-blue">
          <BigText text="Thanks for donating to GTS" />
          <Paragraph
            text="Thank you for your interest in supporting the work of the seminary. Your generosity helps prepare pastors, teachers, missionaries, and Christian leaders to proclaim the gospel of Jesus Christ throughout the world for generations to come."
            className="text-base py-4 leading-7"
          />
        </div>

        <div className="flex flex-col md:flex-row justify-between w-full mt-24">
          <div className="text-blue">
            <BigText text="Ways to Give" />
            <Paragraph
              text="Lorem Ipsum is simply dummy text of the print ing and typesetting industry. Lorem Ipsum has been."
              className="py-4 max-w-xs"
            />
          </div>
          <div className="shadow-lg bg-white p-6">
            <p className="text-xl font-bold text-brown">One Time Donation</p>
            <p className="text-blue font-bold text-base pt-4">
              Lorem Ipsum is simply dummy
            </p>
          </div>
          <div className="shadow-xl bg-white p-6">
            <p className="text-xl font-bold text-brown">
              Monthly recurring gift
            </p>
            <p className="text-blue font-bold text-base pt-4">
              Lorem Ipsum is simply dummy
            </p>
          </div>
        </div>

        <div className="bg-darkgray px-6 py-16 md:p-10 flex flex-col w-full md:flex-row items-center justify-between mt-16 text-center md:text-left">
          <div className="">
            <SectionHeaderText text="Have a Question?" color="#ffffff" />
            <p className="text-white text-xl md:text-base font-bold py-6 md:py-2">
              Feel free to contact us.
            </p>
          </div>
          <Link to="/contact us">
            <div className="flex items-center bg-brown">
              <button className=" p-4 text-white text-lg md:text-sm font-bold text-center">
                Get Started
              </button>
              <img src={arrowRight} alt="arrowRight" />
            </div>
          </Link>
        </div>
      </SectionWrapper>
    </div>
  );
};

export { Support };
