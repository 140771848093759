import React from "react";
import { BigText, Paragraph } from "../atoms";
import { SectionWrapper } from "../layout/SectionWrapper";
import pic from "../assets/outreach-2.png";
import events from "../data/events.js";
import { EventCard } from "../layout/Cards.jsx";
import { RegistrationForm } from "../layout/Forms";
import { Link } from "react-router-dom";

const IndividualOutreach = () => {
  return (
    <div style={{ color: "#3F4648" }}>
      <div className="flex">
        <div className="bg-blue w-4 h-96"></div>
        <SectionWrapper>
          <div>
            <Link to="/Grace Campus Outreach">
              <Paragraph text="Back to outreach" color="#AA8B55" />
            </Link>
            <BigText
              text={
                <p>
                  Ask the Experts with
                  <br /> Dr. Matthew Barrett
                </p>
              }
              className="py-10 text-4xl"
            />
            <Paragraph text="February 23 @ 12:30 pm - 1:30 pm" />
          </div>
        </SectionWrapper>
      </div>
      <div className="md:grid grid-cols-2 gap-10">
        <div className="md:col-span-1">
          <img src={pic} alt="" className="mb-5" />
          <div className="lg:ml-14 lg:pl-20 mb-32">
            <BigText
              text={<p>Related Events</p>}
              color="#AA8B55"
              className="pt-6 mb-10 border-t border-gray-300"
            />
            {events.map((event) => {
              return (
                <EventCard
                  key={event.id}
                  title={event.title}
                  date={event.dateAndTime}
                />
              );
            })}
          </div>
        </div>
        <div className="md:col-span-1 lg:mr-14 lg:pr-20">
          <Paragraph
            text={
              <p>
                Ask the Experts are live, virtual primers from our faculty
                covering a wide <br />
                range of important topics in theology, church history, ministry,
                and more. <br />
                These are held Tuesdays from 12:30-1:30pm. <br />
                <br />
                <br /> Dr. Matthew Barrett’s talk, “Why is a Proper
                Understanding of the Trinity Essential ” will be Tuesday,
                February 23 starting at 12:30pm.
                <br />
                <br />
                <br /> Sessions are open to all. <br />
                <br />
                <br />
              </p>
            }
            color="#3F4648"
          />
          <span className="flex">
            <div className="flex">
              <Paragraph text={<p>Date: </p>} color="#AA8B55" />
              <Paragraph text={<p className="ml-1">February 23</p>} />
            </div>
            <div className="flex ml-2.5">
              <Paragraph text={<p>Time: </p>} color="#AA8B55" />
              <Paragraph
                text={<p className="ml-1">12:30pm - 1:30pm CST</p>}
                className="mb-4"
              />
            </div>
          </span>

          <RegistrationForm />
        </div>
      </div>
    </div>
  );
};
export { IndividualOutreach };
