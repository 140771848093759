import React from "react";

import grace from "../assets/grace.png";
import { SectionHeaderText } from "../atoms";
import { MemberCard } from "../layout/Cards.jsx";
import { board } from "../data/Board";
import { SectionWrapper } from "../layout/SectionWrapper";

const Partnership = () => {
  return (
    <div className="">
      <div className="bg-ash">
        <div className="m-auto w-full md:w-10/12 flex items-center justify-between p-6 md:p-14">
          <div>
            <img src={grace} alt="grace" className="hidden md:block w-1/2" />
          </div>
          <div className="text-center md:text-left">
            <p className="text-grayish text-xl md:text-base font-bold pb-4">
              Partnerships
            </p>
            <SectionHeaderText
              text="Our major partner is Grace Bible University, 
            which is integrally related to Christ Bible Church, 
            PO Box 2190, Dublin, California 94568, USA"
              color="3F4648"
              className="max-w-2xl"
            />
          </div>
        </div>
      </div>
      <SectionWrapper>
        <div className="text-center mt-4">
          <SectionHeaderText
            text="GBU BOARD OF DIRECTORS"
            color="3F4648"
            className="pb-20"
          />
        </div>
        <div className="">
          <div className="md:grid grid-cols-3 gap-12">
            {board.map((member) => {
              return (
                <MemberCard key={member.name} name={member.name} job={member.qualification} />
              );
            })}
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export { Partnership };
