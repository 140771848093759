import React from "react";
import classNames from "classnames";

const Input = ({
  label,
  placeholder,
  id,
  isFocus,
  size,
  type,
  value,
  onInputChange,
  error,
  className,
}) => {
  return (
    <div
      className={classNames(`text-sm ${className}`, {
        "w-full": size === "lg",
        "w-1/2": size === "md",
        "w-1/4": size === "sm",
      })}
    >
      <label htmlFor={id} className="block text-black md:mb-1 xl:mb-3">
        {label}
      </label>
      <input
        type={type}
        autoFocus={isFocus}
        id={id}
        className={classNames(
          "rounded-sm border-lightgray text-lightBlue placeholder-gray-400 font-bold text-xl w-full px-6 py-4 md:px-4 md:py-3",
          {
            "border-red-500": error,
          }
        )}
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
      />
      <span className={classNames("text-red-500", { invisible: error })}>
        {error}
      </span>
    </div>
  );
};

export { Input };
