import React from "react";

import Onecurriculum from "../assets/curriculum.png";
import curriculumOne from "../assets/curriculumOne.png";
import Twocurriculum from "../assets/curriculumTwo.png";
import Threecurriculum from "../assets/curriculumThree.png";
import {
  curriculum,
  curriculumTwo,
  curriculumThree,
  curriculumFour,
  curriculumFive,
  curriculumSix,
  curriculumSeven,
  curriculumEight,
} from "../data/Curriculum";

const Scheme = () => {
  return (
    <div>
      <div className="md:grid md:grid-cols-3">
        <div className="relative text-white hover:animate-bounce">
          <img
            src={curriculumOne}
            alt="curriculumOne"
            className="object-cover w-full h-full"
          />
          <div className="absolute bottom-2 left-4">
            <p className="text-sm font-extrabold">Year 1</p>
            <p className="text-2xl font-bold">
              Certificate of Ministry (30 Units)
            </p>
          </div>
        </div>

        <div className="py-6 px-10 bg-lightOrange">
          <p className="text-grayish uppercase text-sm font-medium py-4">
            first semester
          </p>
          {curriculum.map((course) => {
            return course.courses.map((title, index) => {
              return (
                <div className="flex items-center mb-4" key={index}>
                  <div className="bg-brown w-6 h-1 mr-2"></div>
                  <p className="text-sm font-medium text-blue capitalize">
                    {title.name}
                  </p>
                </div>
              );
            });
          })}
        </div>

        <div className="py-6 px-10 bg-lightPink">
          <p className="text-grayish uppercase text-sm font-medium py-4">
            second semester
          </p>
          {curriculumTwo.map((course) => {
            return course.courses.map((title, index) => {
              return (
                <div className="flex items-center mb-4" key={index}>
                  <div className="bg-brown w-6 h-1 mr-2"></div>
                  <p className="text-sm font-medium text-blue capitalize">
                    {title.name}
                  </p>
                </div>
              );
            });
          })}
        </div>
      </div>

      <div className="md:grid md:grid-cols-3 mt-6 text-white">
        <div className="relative text-white">
          <img
            src={Twocurriculum}
            alt="curriculumOne"
            className="object-cover w-full h-full"
          />
          <div className="absolute bottom-2 left-4">
            <p className="text-sm font-extrabold">Year 2</p>
            <p className="text-2xl font-bold">
              Associate Degree of Ministry (60 Units)
            </p>
          </div>
        </div>
        <div className="py-6 px-10" style={{ backgroundColor: "#866D42" }}>
          <p className="text-white uppercase text-sm font-medium py-4">
            third semester
          </p>
          {curriculumThree.map((course) => {
            return course.courses.map((title, i) => {
              return (
                <div key={i} className="flex items-center mb-4">
                  <div className="bg-brown w-6 h-1 mr-2"></div>
                  <p className="text-sm font-medium capitalize">{title.name}</p>
                </div>
              );
            });
          })}
        </div>

        <div
          className="py-6 pl-10 pr-20 text-white"
          style={{ backgroundColor: "#9A7E4F" }}
        >
          <p className=" uppercase text-sm font-medium py-4">fourth semester</p>
          {curriculumFour.map((course) => {
            return course.courses.map((title, i) => {
              return (
                <div className="flex items-center mb-4" key={i}>
                  <div className="bg-brown w-6 h-1 mr-2"></div>
                  <p className="text-sm font-medium  capitalize">
                    {title.name}
                  </p>
                </div>
              );
            });
          })}
        </div>
      </div>

      <div className="md:grid md:grid-cols-3 mt-6 text-white">
        <div className="relative text-white">
          <img
            src={Threecurriculum}
            alt="curriculumOne"
            className="object-cover w-full h-full"
          />
          <div className="absolute bottom-2 left-4">
            <p className="text-sm font-extrabold">Year 3</p>
            <p className="text-2xl font-bold">
              Bachelor’s Degree of Theology (120 Units)
            </p>
          </div>
        </div>
        <div className="py-6 px-10" style={{ backgroundColor: "#282C2E" }}>
          <p className="text-white uppercase text-sm font-medium py-4">
            fifth semester
          </p>
          {curriculumFive.map((course) => {
            return course.courses.map((title, i) => {
              return (
                <div className="flex items-center mb-4" key={i}>
                  <div className="bg-brown w-6 h-1 mr-2"></div>
                  <p className="text-sm font-medium capitalize">{title.name}</p>
                </div>
              );
            });
          })}
        </div>

        <div
          className="py-6 pl-10 pr-20 text-white"
          style={{ backgroundColor: "#3F4648" }}
        >
          <p className=" uppercase text-sm font-medium py-4">sixth semester</p>
          {curriculumSix.map((course) => {
            return course.courses.map((title, i) => {
              return (
                <div key={i} className="flex items-center mb-4">
                  <div className="bg-brown w-6 h-1 mr-2"></div>
                  <p className="text-sm font-medium  capitalize">
                    {title.name}
                  </p>
                </div>
              );
            });
          })}
        </div>
      </div>

      <div className="md:grid md:grid-cols-3 mt-6 text-white">
        <div className="relative text-white">
          <img
            src={Onecurriculum}
            alt="curriculumOne"
            className="object-cover w-full h-full"
          />
          <div className="absolute bottom-2 left-4">
            <p className="text-sm font-extrabold">Year 4</p>
            <p className="text-2xl font-bold">
              Bachelor’s Degree of Ministry (120 Units)
            </p>
          </div>
        </div>
        <div className="py-6 px-10" style={{ backgroundColor: "#161819" }}>
          <p className="text-white uppercase text-sm font-medium py-4">
            seventh semester
          </p>
          {curriculumSeven.map((course) => {
            return course.courses.map((title, i) => {
              return (
                <div className="flex items-center mb-4" key={i}>
                  <div className="bg-brown w-6 h-1 mr-2"></div>
                  <p className="text-sm font-medium capitalize">{title.name}</p>
                </div>
              );
            });
          })}
        </div>

        <div
          className="py-6 pl-10  text-white"
          style={{ backgroundColor: "#212426" }}
        >
          <p className=" uppercase text-sm font-medium py-4">eight semester</p>
          {curriculumEight.map((course) => {
            return course.courses.map((title, i) => {
              return (
                <div key={i} className="flex items-center mb-4">
                  <div className="bg-brown w-6 h-1 mr-2"></div>
                  <p className="text-sm font-medium  capitalize">
                    {title.name}
                  </p>
                </div>
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default Scheme;
