import React from "react";
import { useLocation } from "react-router-dom";

import { dropdownHeaderItems } from "../data/headerItem";
import SingleDropdown from "./SingleDropdown";

const Dropdown = () => {
  const location = useLocation();
  return (
    <div className="">
      <ul className="hidden list-none md:flex md:justify-between">
        {dropdownHeaderItems.map((navItem) => {
          return (
            <SingleDropdown
              key={navItem.id}
              name={navItem.name}
              subHeading={navItem.subHeading}
              url={navItem.url}
              isActive={location.pathname === navItem.url}
            />
          );
        })}
      </ul>
    </div>
  );
};

// subHeading.map(({ url }) => {
//     return <p>{console.log(url)}</p>;
//   })

export default Dropdown;
