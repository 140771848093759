import React from "react";
import { useLocation } from "react-router-dom";

import { ExtraBigText } from "../atoms/ExtraBigText.jsx";
import header from "../assets/resource-header.JPG";

const Banner = () => {
  const location = useLocation();
  let isActive;

  const path = location.pathname.slice(1);
  const slashIndex = path.indexOf("/");

  if (path.includes("/")) {
    isActive = path.slice(0, slashIndex);
  } else {
    isActive = location.pathname.slice(1);
  }
  const isSubActive = path.slice(slashIndex + 1);

  return (
    <div
      className="md:mt-4"
      style={{
        backgroundImage: `url(${header})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        objectFit: "contain",
        height: "245px",
      }}
    >
      <div className="pt-12 md:ml-16 pl-6 md:pl-14 text-white">
        <ExtraBigText text={isActive} />
        <p className="mt-4 capitalize font-bold text-base">
          {isActive} &gt; <span>{isSubActive}</span>
        </p>
      </div>
    </div>
  );
};

export default Banner;
