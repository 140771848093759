import React from "react";
import { Link } from "react-router-dom";
import { Paragraph, BigText, Button } from "../atoms";
import { SectionHeaderText } from "../atoms/SectionHeaderText";
import { SectionWrapper } from "../layout/SectionWrapper";
import dreamstime from "./../assets/dreamstime.png";
import responsiveDreamstime from "./../assets/dreamstime-2.png";
import graceLogo from "./../assets/GTS website images/Partnership.png";
//import tpcLogo from "./../assets/tpcLogo.png";

const About = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <SectionWrapper>
      <div className="md:grid md:grid-rows-1 lg:grid-rows-2 grid-flow-col gap-8">
        <div className="lg:row-span-2">
          <picture>
            <source media="(max-width: 768px)" srcSet={responsiveDreamstime} />
            <img
              src={dreamstime}
              alt="dreamstime"
              className="w-full h-full lg:w-56 object-cover"
            />
          </picture>
        </div>
        <div className="col-span-2">
          <SectionHeaderText
            text="Introduction & Purpose"
            color="#AA8B55"
            className="my-3 lg:my-0"
            id="introduction"
          />
          <Paragraph
            text="Grace & Truth Theological Seminary (GTS) is an educational ministry instituted to 
          train men who have been called into ministry and or preparing for pastoral ministry and women who 
          want to be equipped with the knowledge of God. It is committed to delivering qualitative education 
          in theology and pastoral studies; which provides students with a firm foundation to properly divide 
          the word of God. GTS is a ministry of Sovereign Grace Bible Church, and is affiliated with Grace Bible University (USA)."
            color="#3F4648"
            className="mb-3 lg:mb-0"
          />
        </div>
        <div className="row-span-1 col-span-2 bg-blue py-10 px-4 md:p-8">
          <Paragraph
            text="GTS is a Reformed-Baptist, (1689 Baptist confession-believing) seminary, welcoming applications 
          from anyone who truly desires to be a servant of God. Through Grace Bible University (GBU), GTS offers 1 year 
          program for Certificate in Ministry, 2 years program for Associate Degree of Ministry, and 4 years program for 
          Bachelor’s degree of Theology and Bachelor’s degree of Ministry awarded upon completion of the course requirements."
            color="#FFFFFF"
          />
        </div>
      </div>

      <div className="lg:grid grid-col-3 grid-flow-col gap-4 mt-8">
        <div className="col-span-2 text-white bg-brown py-10 px-4 md:p-8 gap-10">
          <SectionHeaderText
            text="Mission Statement"
            color="#FFFFFF"
            id="missionStatement"
          />
          <Paragraph
            text="Our mission is to equip men and women for spiritual ministry. Bearing in mind that 
          only the ministry of the Holy Spirit can produce results acceptable to God and redound to His glory; 
          and ultimately enduring unto eternal life (John 4:23 -24, 1Peter 2:4 -5, 2Corinthians 3:6-9, John 6: 63, 
          1Corinthians 3 :10-17)."
          />
          <Paragraph
            className="mt-4"
            text={
              <p>
                Our method of preparing men is a three-pronged, Biblical
                approach: <br /> 1.Doctrine (2 Timothy 3:16,17) <br />{" "}
                2.Meditation (1 Corinthians 2:6-16) <br /> 3.Mentoring (2Timothy
                2:2; Mathew 28:19,20)
              </p>
            }
          />
        </div>
        <div className="bg-white col-span-1 shadow-xl py-10 px-4 md:p-8 flex flex-col justify-between">
          <Paragraph
            text="In Accomplishing this mission, our training program has been tailored 
          according to the Bible’s guidelines for ministry preparation,
           which informs our “purpose”, curriculum, and quality of the faculty members."
          />
          <Button bgColor="#3F4648" className="mt-8 w-2/3 md:w-44" onClick={scrollToTop}>
              <Link to="/academic/degree">View Degrees</Link>
            </Button>
        </div>
      </div>

      <div className="text-center">
        {/* <SectionHeaderText
          text="See What Professor Have To Say"
          color="#3F4648"
          className="py-16"
        />
        <BigText
          text={
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam,{" "}
              <span className="text-brown"> quis nostrud ut labore </span>et
              dolore magna nostrud.
            </p>
          }
          color="#3F4648"
        /> */}

        {/* <div className="flex items-center justify-center my-16">
          <p className="text-xl text-brown font-extrabold mr-4">
            @Professor GTS
          </p>

          <img src={twitter} alt="twitter" />
        </div> */}
      </div>

      <div className="flex flex-col justify-between md:grid md:grid-cols-3 py-8">
        <div className="">
          <BigText text="Affiliates" className="text-center md:text-left" />
          <Paragraph
            text={
              <p>
                Grace Bible University
                <br /> PO Box 2190, Dublin,
                <br />
                California 94568, USA
              </p>
            }
            className="max-w-xs"
          />
        </div>
        <div className="bg-white p-4 shadow-lg flex justify-center">
          <img src={graceLogo} alt="Grace Bible Universitya" className="object-contain h-40" />
        </div>
        {/* <div className="bg-white p-4 shadow-lg flex justify-center">
          <img src={tpcLogo} alt="tpcLogo" className="object-contain h-40" />
        </div> */}
      </div>
    </SectionWrapper>
  );
};

export { About };
