import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import classNames from "classnames";

import { ArrowDown, ArrowUp } from "../atoms";

const SingleDropdown = ({
  name,
  subHeading,
  isActive,
  url,
  className,
  onClick,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const location = useLocation();
  const toggleDropdown = (e) => {
    setShowDropDown(!showDropDown);

  };
  const arrow = useRef();

  const closeDropDown = () => {
    setShowDropDown(false);
  };
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showDropDown && arrow.current && !arrow.current.contains(e.target)) {
        setShowDropDown(false);
      }
    } 
    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside)
    }
  }, [showDropDown])

  return (
    <div className="">
      <div
        className={`flex items-center justify-between cursor-pointer ${className}`}
      >
        <li
          className={classNames(
            "text-blue text-base font-bold flex items-center",
            {
              "text-brown": isActive,
            }
          )}
        >
          <Link className="no-underline flex items-center" to={url}>
            {name}
          </Link>
          <div className="ml-1 mr-3" onClick={toggleDropdown} ref={arrow}>
            {showDropDown ? <ArrowUp /> : <ArrowDown />}
          </div>
        </li>
      </div>
      <div className="bg-white shadow-3xl absolute z-5 mt-6 px-8">
        {setShowDropDown
          ? subHeading?.map((sub) => {
              return (
                <div className="w-48" key={sub.id}>
                  {showDropDown ? (
                    <Link
                      id={sub.id}
                      to={sub.url}
                      key={sub.id}
                      smooth
                      onClick={closeDropDown}
                    >
                      <p
                        className={classNames(
                          "text-blue text-base font-bold pt-2 pb-2",
                          {
                            "text-brown": location.pathname === sub.url,
                          }
                        )}
                      >
                        {sub.title}
                      </p>
                    </Link>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default SingleDropdown;
