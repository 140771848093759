import React from "react";

const ExtraBigText = ({ text, color, className }) => {
  return (
    <div>
      <p
        className={`text-5xl lg:text-6xl font-bold capitalize ${className}`}
        style={{ color: color }}
      >
        {text}
      </p>
    </div>
  );
};

export {ExtraBigText};
