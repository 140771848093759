import { SectionWrapper } from "../layout/SectionWrapper.jsx";
import { ResourceCard } from "../layout/Cards.jsx";
import resources from "../data/resources.js";

const Resources = () => {
	return (
		<div>
			<SectionWrapper>
				<div className="grid lg:grid-cols-3 gap-12">
					{resources.map((resource) => {
						return (
							<ResourceCard
								key={resource.id}
								date={resource.date}
								img={resource.imgURL}
								title={resource.title}
								body={resource.body}
							/>
						);
					})}
				</div>
				{/* <div className="flex justify-center my-20">
					<button className="bg-brown text-white px-5 py-2">Load More</button>
				</div> */}
			</SectionWrapper>
		</div>
	);
};

export { Resources };
