import React from "react";
import { Paragraph, SectionHeaderText, BigText } from "../atoms";

import { SectionWrapper } from "../layout/SectionWrapper";
import bibleReader from "./../assets/bibleReader.png";

const Philosophy = () => {
  return (
    <SectionWrapper>
      <div className="lg:grid grid-cols-2">
        <div className="">
          <img src={bibleReader} alt="bibleReader" className="h-full w-full object-cover" />
        </div>
        <div className="bg-brown px-4 py-12 md:p-10 lg:py-6 lg:px-12 ">
          <SectionHeaderText
            text="Philosophy of Education"
            color="#ffffff"
            className="pb-8 max-w-xs"
          />
          <Paragraph
            text="GTS adopts the philosophy of education of GBU, 
          which is based upon a Christian theistic world-and-life view, an inclusive philosophy of life that derives 
          consistently from the Scriptures.’"
            color="#ffffff"
            className="pb-8"
          />
          <Paragraph
            text="In Christian education, God and his Word are presupposed to be absolute and ultimate. Therefore, Scripture is the final criterion and standard for all truth and knowledge."
            color="#ffffff"
          />
        </div>
      </div>

      <div className="md:text-center">
        <p className="text-blue text-base font-bold leading-7 px-4 py-12 md:p-10" style={{background: '#f6f6f6'}}>
          This is the first and overshadowing presupposition for a truly
          biblical approach to education. There is no higher authority for man
          in knowledge and reasoning. God is absolute and his revelation comes
          to man with absolute authority; it is the inspired, infallible,
          inerrant, and all- sufficient word of God.
        </p>

        <BigText
          text="Thus, Scripture is held to be the supreme authority in every area of life
         for the individual, the family, the church, society and government."
          color="#3F4648"
          className="pt-16 pb-8 text-center"
        />
        <p className="text-brown text-sm font-bold">(2 Timothy 3:16)</p>
      </div>
    </SectionWrapper>
  );
};

export { Philosophy };
