import React from "react";
import arrowRight from "./../assets/arrowRight.svg";

const Button = ({
  children,
  // variant,
  // type,
  // disabled,
  // size,
  onClick,
  width,
  text,
  bgColor,
  className,
}) => {
  return (
    <div
      className={`flex ${className}`}
      style={{ backgroundColor: bgColor, width: width }}
    onClick={onClick}>
      <button className=" p-4 text-white text-xl md:text-sm font-extrabold md:font-bold">
        {children}
      </button>
      <img src={arrowRight} alt="arrowRight" />
    </div>
  );
};

export { Button };
