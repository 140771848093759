import resource1 from '../assets/bibleReader@2x.png'
import resource2 from '../assets/resource-2.png';
import resource6 from '../assets/philosophy.png';

const resources = [
    {
        id: 1,
        imgURL: resource1,
        date: "April 28, 2019",
        title: "10 Benefits All New Covenant Members Enjoy",
        body: "he following is a short excerpt from a sermon on Isaiah 54:10 preached by 17th Century Baptist, Benjamin Keach (1640-1704). In this sermo…"
    },
    {
        id: 2,
        imgURL: resource2,
        date: "April 28, 2019",
        title: "10 Benefits All New Covenant Members Enjoy",
        body: "he following is a short excerpt from a sermon on Isaiah 54:10 preached by 17th Century Baptist, Benjamin Keach (1640-1704). In this sermo…"
    },
    {
        id: 3,
        imgURL: resource2,
        date: "April 28, 2019",
        title: "10 Benefits All New Covenant Members Enjoy",
        body: "he following is a short excerpt from a sermon on Isaiah 54:10 preached by 17th Century Baptist, Benjamin Keach (1640-1704). In this sermo…"
    },
    {
        id: 4,
        imgURL: resource2,
        date: "April 28, 2019",
        title: "10 Benefits All New Covenant Members Enjoy",
        body: "he following is a short excerpt from a sermon on Isaiah 54:10 preached by 17th Century Baptist, Benjamin Keach (1640-1704). In this sermo…"
    },
    {
        id: 5,
        imgURL: resource2,
        date: "April 28, 2019",
        title: "10 Benefits All New Covenant Members Enjoy",
        body: "he following is a short excerpt from a sermon on Isaiah 54:10 preached by 17th Century Baptist, Benjamin Keach (1640-1704). In this sermo…"
    },
    {
        id: 6,
        imgURL: resource6,
        date: "April 28, 2019",
        title: "10 Benefits All New Covenant Members Enjoy",
        body: "he following is a short excerpt from a sermon on Isaiah 54:10 preached by 17th Century Baptist, Benjamin Keach (1640-1704). In this sermo…"
    },
];

export default resources;