import React from "react";
import { BigText, Paragraph } from "../../atoms";
import arrowRight from "../../assets/arrowRight.svg";

const OutreachOne = ({ dd, mm, yy, title, image, description }) => {
  return (
    <div className="md:grid grid-cols-2 gap-12 lg:gap-4">
      <div className="flex justify-between">
        <div className="self-center">
          <p className="text-7xl font-bold text-blue">{dd}</p>
          <hr className="w-20" />
          <p className="font-bold text-blue">{`${mm} ${yy}`}</p>
        </div>
        <img src={image} alt="resources" className="w-5/6 object-cover" />
      </div>
      <div className="mt-6 md:mt-0">
        <BigText
          text={title}
          color="#3F4648"
          className="max-w-sm"
        />
        <Paragraph text={`${mm} ${dd}, ${yy}`} color="#3F4648" className="my-6" />
        <Paragraph text={description} color="#3F4648" className="max-w-lg"/>
        <section className="flex pb-4">
          {/* <Link to="/individual Outreach">
            <Paragraph
              text="Learn More"
              color="#AA8B55"
              className="font-bold "
            />
          </Link> */}
          <img src={arrowRight} alt="" />
        </section>

        <Paragraph
          text={
            <p>
              <span className="text-brown">Date:</span> {`${mm} ${dd} `}
              <span className="text-brown">Time:</span> 11am
            </p>
          }
        />
      </div>
    </div>
  );
};

export default OutreachOne;
