export const headerItems = [
  // {
  //   id: "2",
  //   name: "Resources",
  //   url: "/resources",
  // },
  {
    id: "3",
    name: "Outreach",
    url: "/Grace Campus Outreach",
  },
];

export const dropdownHeaderItems = [
  {
    id: "1",
    name: "About",
    url: "/about",
    subHeading: [
      {
        id: "1",
        title: "Introduction & Purpose",
        url: "/about/#introduction"
      },
      {
        id: "2",
        title: "Mission Statement",
        url: "/about/#missionStatement"
      }
    ],
  },
  {
    id: "2",
    name: "Admission",
    url: "/admission/Admission policy",
    subHeading: [
      {
        id: "1",
        title: "Admission policy",
        url: "/admission/Admission policy/#admissionPolicy",
      },
      {
        id: "2",
        title: "Apply Now",
        url: "/admission/Admission policy/#applyNow"
      },
    ],
  },
  {
    id: "3",
    name: "Academic",
    url: "/academic/degree",
    subHeading: [
      {
        id: "1",
        title: "Degrees & Curriculum ",
        url: "/academic/degree/#degreesAndCurriculum"
      },
      {
        id: "2",
        title: "Course Description",
        url: "/academic/degree/#courseDescription"
      },
    ],
  },
];
