import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link"; 
import classNames from "classnames";

import { ArrowDown, ArrowUp } from "../atoms";

const MobileDropdown = ({ name, subHeading, isActive, url, className }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => {
    setShowDropDown(!showDropDown);
  };

  const arrow = useRef();
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showDropDown && arrow.current && !arrow.current.contains(e.target)) {
        setShowDropDown(false);
      }
    } 
    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside)
    }
  }, [showDropDown]);

  return (
    <div className="">
      <div
        className={`ml-8 flex items-center cursor-pointer mr-6 ${className}`}
      >
        <li
          className={classNames("text-blue pr-4 text-xl font-bold", {
            "text-brown": isActive,
          })}
        >
          <Link className="no-underline" to={url}>
            {name}
          </Link>
        </li>
        <div className="" onClick={toggleDropdown} ref={arrow}>
          {showDropDown ? <ArrowUp /> : <ArrowDown />}
        </div>
      </div>
      <div className="mt-4 px-8">
        {setShowDropDown &&
          subHeading?.map((sub) => {
            return (
              <div className="w-48 mb-4">
                {showDropDown && (
                  <Link to={sub.url}
                      key={sub.id}
                      activeClassName="selected"
                      activeStyle={{color: "text-brown"}}
                      className={classNames(
                        "text-blue font-bold pb-2",
                        {
                          "text-brown": location.pathname === sub.url,
                        }
                      )}
                      smooth
                    >
                      {sub.name}
                  </Link>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MobileDropdown;
