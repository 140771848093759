import { useState } from "react";
import { useLocation } from "react-router-dom";

import NavItem from "../atoms/NavItem";
import MobileDropdown from "./MobileDropdown";

const SubMenuTwo = ({ item }) => {
  const location = useLocation();
  const [dropDown, setDropDown] = useState(false);
  const showSubnav = () => {
    if (item.subNav) setDropDown(!dropDown);
  };
  return (
    <div>
      <>
        {item.subHeading ? (
          <ul className="list-none mb-6">
            <MobileDropdown
              key={item.id}
              name={item.name}
              subHeading={item.subHeading}
              url={item.url}               
              isActive={location.pathname === item.url}
            />
          </ul>
        ) : (
          <div className="-mt-4">
            <NavItem
              key={item.url}
              isActive={location.pathname === item.url}
              item={item}
              className="text-xl font-semibold items-center p-5 list-none ml-3 cursor-pointer"
              onClick={showSubnav}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default SubMenuTwo;
