export const admissionParts = [
  {
    id: "01",
    tagline: "Review",
    body: "The questions and answers are based upon the text. There will be no answer that cannot be easily found within the material. As much as possible, we advice that each student uses a complete sentence when responding to the questions. Such discipline will help the student to learn to think clearly and logically.",
    bgColor: "#3F4648",
    textColor: "#ffffff",
  },
  {
    id: "02",
    tagline: "Scriptural Memory Work",
    body: "The student is expected to memorise, and recite without help, the passages of Scripture indicated, in order to complete the individual course. Chapter and verse must be included in the memorisation response.",
    bgColor: "#ffffff",
    textColor: "#3F4648",
  },
  {
    id: "03",
    tagline: "Personal Application",
    body: "Practical suggestions are offered on how the material studied can be applied in life.",
    bgColor: "#AA8B55",
    textColor: "#ffffff",
  },
];

export const admissionRequirements = [
  {
    id: "01",
    text: "Completion of secondary school or equivalent educational level. (Applicant must be able to read and write)",
  },
  {
    id: "02",
    text: "Applicant must dully fill out registration forms and submit accordingly.",
  },
  {
    id: "03",
    text: "A letter of recommendation from a church officer, approving the student’s desire to study at GTS. Exceptions may be considered on a case-by-case basis by the board of the seminary.",
  },
  {
    id: "04",
    text: "Applicant must send in two references with the information - full name, profession, designation, home or office address, email, phone number, relationship to applicant.",
  },
];
