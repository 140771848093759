import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

const NavItem = ({ isActive, item, className }) => {
  return (
    <li
      className={classNames(`text-blue text-base font-bold mr-6 ${className}`, {
        "text-brown": isActive,
      })}
    >
      <Link className="no-underline" to={item.url}>
        {item.name}
      </Link>
    </li>
  );
};

export default NavItem;
