import React, { forwardRef } from "react";

const SectionHeaderText = forwardRef(({ text, color, className, id }, ref) => {
  return (
    <div ref={ref} id={id}>
      <p
        className={`text-3xl md:text-2xl font-black md:font-bold capitalize ${className}`}
        style={{ color: color }}
      >
        {text}
      </p>
    </div>
  );
});

export { SectionHeaderText };
