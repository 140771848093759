import React from "react";
import { BigText, Paragraph } from "../../atoms";
import arrowRight from "../../assets/arrowRight.svg";

const OutreachFour = ({ dd, mm, yy, title, image, description }) => {
  return (
    <div className="md:grid grid-cols-2 gap-12 lg:gap-4 mb-14">
      <div className="flex justify-between">
        <div className="self-center">
          <p className="text-7xl font-bold text-blue">{dd}</p>
          <hr className="w-20" />
          <p className="font-bold text-blue">{`${mm} ${yy}`}</p>
        </div>
        <img src={image} alt="resources" className="w-5/6 object-cover" />
      </div>
      <div className="mt-6 md:mt-0">
        <BigText
          text={title}
          color="#3F4648"
          className="max-w-sm"
        />
        <Paragraph text={`Oct 17th - 28th, 2022`} color="#3F4648" className="my-6" />
        <Paragraph text={description} color="#3F4648" className="max-w-lg"/>
        <section className="flex pb-4">
          <img src={arrowRight} alt="" />
        </section>

        <Paragraph
          text={
            <p>
              <span className="text-brown">Date:</span> Oct 17th - 28th
              <span className="text-brown ml-2">Time:</span> 9:30am
            </p>
          }
        />
      </div>
    </div>
  );
};

export default OutreachFour;
