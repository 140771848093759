import React from "react";

import { Paragraph, SectionHeaderText } from "../atoms";
import arrowRight from "./../assets/arrowRight.svg";
import { SectionWrapper } from "../layout/SectionWrapper";
import Scheme from "../templates/Scheme";
import Accordion from "../templates/Accordion";
import { SchemeDetails } from "../data/SchemeDetails";

const Curriculum = () => {
  return (
    <div className="">
      <SectionWrapper>
        <div className="text-center">
          <p className="text-grayish text-xl md:text-base font-extrabold md:font-bold" id="degreesAndCurriculum">
            Degrees & Curriculum
          </p>
          <SectionHeaderText
            text="The curriculum follows a progression from the Certificate of Ministry to the Bachelor’s Degree."
            color="#3F4648"
            className="text-3xl pt-8"
          />
          <p className="text-xl md:text-lg capitalize py-8 text-blue font-medium">
            The credits of each degree can be applied to next higher degree.
          </p>
        </div>

        <Scheme />

        <div className="text-center mt-12">
          <p className="text-brown text-2xl md:text-lg font-bold md:font-medium my-12 md:my-4">
            (All courses are 3 units each)
          </p>
          <Paragraph
            text="The Bachelor’s Degree of Theology differs from the Bachelor’s
         Degree of Ministry only in the final year of study.
         The change in course requirements is identified in the curriculum section below."
            className="text-sm"
          />
        </div>
      </SectionWrapper>

      <div className="bg-ash pb-36">
        <div className="m-auto w-11/12 md:w-10/12 p-6">
          <p className="text-grayish text-xl md:text-xs uppercase font-bold py-6">
            Degrees & Curriculum
          </p>
          <SectionHeaderText text="Course Description" color="#3F4648" id="courseDescription"/>
        </div>

        <div className="">
          <div className="m-auto w-10/12">
            {SchemeDetails.map((course, index) => {
              return (
                <Accordion
                  key={index}
                  course={index}
                  title={course.courseTitle}
                  courseDetails={course.courseDetails}
                />
              );
            })}
          </div>
        </div>
      </div>

      <SectionWrapper>
        <div className="bg-darkgray md:flex justify-between p-10">
          <div className="text-white">
            <SectionHeaderText
              text="Meet our Faculty"
              className="pt-12 md:pt-0"
            />
            <Paragraph
              text="See the list of our faculty"
              className="py-6 md:py-0"
            />
          </div>
          .
          <div
            className={`flex`}
            style={{ backgroundColor: "#AA8B55", width: "20%" }}
          >
            <button className=" p-4 text-white text-center text-xl md:text-sm font-extrabold md:font-bold">
              Download Now
            </button>
            <img src={arrowRight} alt="arrowRight" />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export { Curriculum };
