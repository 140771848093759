export const curriculum = [
  {
    id: 1,
    semester: "first",
    courses: [
      {
        id: 1,
        name: "Bible Doctrine I How to study the Bible",
      },
      {
        id: 2,
        name: "Church History I",
      },
      {
        id: 3,
        name: " New Testament Missionary ",
      },
      {
        id: 4,
        name: "MZ Elective",
      },
    ],
  },
];

export const curriculumTwo = [
  {
    id: 2,
    semester: "second",
    courses: [
      {
        id: 1,
        name: "Bible Doctrine II",
      },
      {
        id: 2,
        name: " Sovereignty of God",
      },
      {
        id: 3,
        name: " Introduction to the Scriptures ",
      },
      {
        id: 4,
        name: " Holiness",
      },
      {
        id: 5,
        name: "MZ Elective",
      },
    ],
  },
];
export const curriculumThree = [
  {
    id: 3,
    semester: "third",
    courses: [
      {
        id: 1,
        name: "Biblical Hermeneutics I",
      },
      {
        id: 2,
        name: "Old Testament Survey",
      },
      {
        id: 3,
        name: "New Testament Survey",
      },
      {
        id: 4,
        name: "Church History II",
      },
      {
        id: 5,
        name: "MZ Elective",
      },
    ],
  },
];

export const curriculumFour = [
  {
    id: 4,
    semester: "fourth",
    courses: [
      {
        id: 1,
        name: "Biblical Hermeneutics II",
      },
      {
        id: 2,
        name: "Old Testament Survey II",
      },
      {
        id: 3,
        name: "New Testament Survey II",
      },
      {
        id: 4,
        name: "The Life of Christ",
      },
      {
        id: 5,
        name: "MZ Elective",
      },
    ],
  },
];

export const curriculumFive = [
  {
    id: 5,
    semester: "fifth",
    courses: [
      {
        id: 1,
        name: "Cults and World Religions",
      },
      {
        id: 2,
        name: " New Testament Survey III",
      },
      {
        id: 3,
        name: " Theology of Prayer",
      },
      {
        id: 4,
        name: "Major Prophets",
      },
      {
        id: 5,
        name: "MZ Elective",
      },
    ],
  },
];

export const curriculumSix = [
  {
    id: 6,
    semester: "sixth",
    courses: [
      {
        id: 1,
        name: "Theology of Prayer II",
      },
      {
        id: 2,
        name: "History of Revival",
      },
      {
        id: 3,
        name: " Minor Prophets",
      },
      {
        id: 4,
        name: " Life of George Muller",
      },
      {
        id: 5,
        name: "MZ Elective",
      },
    ],
  },
];

export const curriculumSeven = [
  {
    id: 7,
    semester: "seventh",
    courses: [
      {
        id: 1,
        name: "Theological Propaedeutic I",
      },
      {
        id: 2,
        name: "Reformation History",
      },
      {
        id: 3,
        name: "Elementary Greek",
      },
      {
        id: 4,
        name: " Presuppositional Apologetics",
      },
      {
        id: 5,
        name: "MZ Elective",
      },
    ],
  },
];

export const curriculumEight = [
  {
    id: 8,
    semester: "eight",
    courses: [
      {
        id: 1,
        name: "Pastoral Theology Theological",
      },
      {
        id: 2,
        name: "Propaedeutic II",
      },
      {
        id: 3,
        name: "Elementary Hebrew",
      },
      {
        id: 4,
        name: " Homiletic ",
      },
      {
        id: 5,
        name: "MZ Elective",
      },
    ],
  },
];
