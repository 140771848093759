import React from "react";
import { Paragraph, SectionHeaderText } from "../atoms";
import { SectionWrapper } from "../layout/SectionWrapper";
import { admissionParts } from "../data/AdmissionCard";
import { admissionRequirements } from "../data/AdmissionCard";
import comma from "../assets/invertedComma.svg";

const Admission = () => {
  return (
    <div className="">
      <SectionWrapper>
        <div className="text-center">
          <p
            className="text-grayish text-2xl md:text-base font-bold"
            id="admissionPolicy"
          >
            Admission Policy
          </p>
          <SectionHeaderText
            text="Admission is open to both male and female of any age and of any religious persuasion."
            color="#3F4648"
            className="text-3xl pt-8"
          />
        </div>
      </SectionWrapper>
      <div className="m-auto w-full md:w-11/12 md:grid grid-rows-2 grid-cols-7 mt-16">
        <div className="row-span-1 col-span-2 bg-brown p-6 pb-44 md:pb-0">
          <SectionHeaderText
            text="Applying to Grace & Truth Theological Seminary (Gts)"
            color="#ffffff"
            className="text-center md:text-left leading-10"
          />
        </div>
        <div className="flex row-span-2 col-start-3 col-end-7 bg-white card-border border-blue shadow-xl p-6 -mt-36 md:mt-0">
          <div className="">
            <p className="text-xl font-bold capitalize text-blue py-4">
              The following registration requirements must be met by the student
              before consideration of acceptance to GTS:
            </p>
            {admissionRequirements.map(({ id, text }) => {
              return (
                <div className="flex" key={id}>
                  <div className="bg-grayish p-2 w-10 h-10 mb-6 mr-4">
                    <p className="text-white mr-4">{id}</p>
                  </div>
                  <p className="text-blue text-xl md:text-lg font-medium md:font-bold pb-6">
                    {text}
                  </p>
                </div>
              );
            })}
            <p className="text-brown py-10 text-lg font-medium">
              The student’s application will be evaluated, after which an
              interview will be conducted by one or more of the board of GTS.
              All admitted students will be contacted and all names published on
              the GTS website.
            </p>
          </div>
        </div>
        <div className="hidden md:block bg-brown w-8 h-56"></div>
      </div>

      <div className="mt-10 bg-ash mb-10 py-12 px-4 md:px-20">
        <div className="m-auto w-full md:w-10/12 text-center">
          <p className="text-grayish text-xl md:text-base font-bold pb-4">
            Study Guide Method
          </p>
          <SectionHeaderText
            text="GTS has fully adopted the study methodology of GBU which has developed a unique method of study."
            color="#3F4648"
          />
          <Paragraph
            text="Each course has a Student’s Study Guide which provides a workbook for the student. A qualified mentor will have the same workbook but with the answers."
            color="#3F4648"
            className="text-base pt-6"
          />
        </div>
      </div>

      <div className="mt-12">
        <SectionHeaderText
          text="There are five parts to each course:"
          color="#AA8B55"
          className="text-center pb-4"
        />
      </div>

      <SectionWrapper>
        <div className="md:flex">
          {admissionParts.map(({ id, tagline, body, bgColor, textColor }) => {
            return (
              <div
                key={id}
                className="shadow-2xl px-6 py-4 md:mr-6"
                style={{ backgroundColor: bgColor, flex: "1" }}
              >
                <p className="text-8xl font-bold" style={{ color: textColor }}>
                  {id}
                </p>
                <p
                  className="text-base font-bold py-4"
                  style={{ color: textColor }}
                >
                  {tagline}
                </p>
                <Paragraph text={body} color={textColor} />
              </div>
            );
          })}
        </div>

        <div className="md:grid grid-cols-3 mt-10">
          <div
            className="col-span-2 bg-white shadow-2xl px-6 py-10 md:mr-6"
            style={{ color: "#3F4648" }}
          >
            <p className="text-8xl font-bold">04</p>
            <p className="text-base font-bold py-4">
              Reflection and Discussion
            </p>
            This part of the course is designed to let the student interact with
            the mentor on a one-to-one basis. While there is much freedom to
            respond, all responses should ultimately end within the framework of
            a Christian worldview and be defended by the Word of God. If, upon
            discussion, the mentor finds that a student’s answer needs to be
            more biblically based, he will have the student either rewrite or
            add to a portion of their response.
          </div>
          <div
            className="col-span-1 shadow-2xl px-6 py-10 md:mr-6"
            style={{ color: "#ffffff", backgroundColor: "#282C2E" }}
          >
            <p className="text-8xl font-bold">05</p>
            <p className="text-base font-bold py-4">Heart Work</p>
            This is a private and personal assessment of one’s own heart. It
            does not need to be shared or discussed. “Examine yourselves as to
            whether you are in the faith. Test yourselves. Do you not know
            yourselves, that Jesus Christ is in you? — unless indeed you are
            disqualified.” 2 Cor. 14:5, NKJV.
          </div>
        </div>

        <div className="mt-6">
          <img src={comma} alt="comma" className="w-20" />
          <SectionHeaderText
            text="For successful completion of the programs, each student is expected 
          to read the text, answer all questions completely, review the material, and interact with 
          the Mentor on the personal application and reflection section"
            className="-mt-20 md:-mt-28 ml-4 md:ml-20"
          />

          <div className="flex justify-end -mt-36">
            <img
              src={comma}
              alt="comma"
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>

        <div className="bg-darkgray px-4 py-16 md:p-10 flex flex-col w-full md:flex-row items-center justify-between mt-16 text-center md:text-left">
          <div className="">
            <SectionHeaderText text="Apply Now" color="#ffffff" id="applyNow" />
            <p className="text-white text-xl md:text-base font-bold py-6 md:py-2">
              Click here to apply.
            </p>
          </div>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfCLg_ywSAO9qpmiBANpu1VgG8_lktgU1S-hExGjFGrgUVYSA/viewform"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center bg-brown">
              <button className=" p-4 text-white text-lg md:text-sm font-bold text-center">
                Get Started
              </button>
            </div>
          </a>
        </div>
      </SectionWrapper>
    </div>
  );
};

export { Admission };
