import React from "react";
import { BigText, Paragraph } from "../../atoms";
import arrowRight from "../../assets/arrowRight.svg";

const OutreachThree = ({ dd, mm, yy, title, image, description }) => {
  return (
    <div className="flex flex-col-reverse md:flex-row justify-between mt-6 mb-12 ">
      <div className="md:mr-4 lg:mr-0" style={{ flex: "1" }}>
        <BigText
          text={title}
          color="#3F4648"
          className="max-w-sm mt-6 md:mt-0"
        />
        <Paragraph text={`${mm} ${dd}, ${yy}`} color="#3F4648" className="my-6" />
        <Paragraph
          text={description}
          color="#3F4648"
        />
        <section className="flex pb-4">
          <img src={arrowRight} alt="" />
        </section>

        <Paragraph
          text={
            <p>
              <span className="text-brown">Date:</span> {`${mm} ${dd} `}
              <span className="text-brown">Time:</span> From 9:30am
            </p>
          }
        />
      </div>

      <div className="flex justify-between" style={{ flex: "1" }}>
        <img src={image} alt="resources" className="w-5/6 object-cover" />
        <div className="self-center">
          <p className="text-7xl font-bold text-blue">{dd}</p>
          <hr className="w-20" />
          <p className="font-bold text-blue">{`${mm} ${yy}`}</p>
        </div>
      </div>
    </div>
  );
};

export default OutreachThree;
