import profile1 from "../assets/joe.png";
import tony from "../assets/tony.png";

import osinachi from "../assets/Osinachi.jpeg";
import osagie from "../assets/Osagie.jpeg";
import joel from "../assets/Joel.jpeg";

const boardMembers = [
  {
    id: 1,
    imgURL: profile1,
    name: "Pastor Joe Jacowitz",
    position: "Director",
    link: "/",
  },
  {
    id: 2,
    imgURL: tony,
    name: "Pastor Tony Okoroh",
    position: "Director/Principal",
    link: "/",
  },
  {
    id: 3,
    imgURL: profile1,
    name: "Pastor Osagie Azeta",
    position: "Director/Student Dean",
    link: "/",
  },
  {
    id: 4,
    imgURL: profile1,
    name: "Pastor Osinachi Nwoko",
    position: "Director/Academic Dean",
    link: "/",
  },
  {
    id: 5,
    imgURL: profile1,
    name: "Joel Uzamere",
    position: "Administrator/Admissions Coordinator",
    link: "/",
  },
];

const faculty = [
  {
    id: 1,
    imgURL: osinachi,
    name: "Pastor Osinachi",
    info: "He is a graduate of London Reformed Baptist Seminary (LRBS) and was ordained pastor at Sovereign Grace Bible Church in June 2019. He is currently in Reformed Baptist Seminary (RBS), where he is pursuing his Master's Degree in Theology. He is married to Nenye with whom he has two children, Zikoranachiebuka and Naetochuku."
  },
  {
    id: 2,
    imgURL: osagie,
    name: "Pastor Osagie",
    info: "He is a graduate of the London Reformed Baptist Seminary (LRBS) and Christ Pastor’s Seminary (CPS). Osagie is currently in Reformed Baptist Seminary (RBS), where he is pursuing his Master's Degree in Theology.He was ordained in June 2019 after serving as a deacon for 4 years. Osagie is married to Funmilayo with whom he has two sons, Anthony and Jesse."
  },
  {
    id: 3,
    imgURL: joel,
    name: "Brother Joel",
    info: "He is a graduate of  Christ Pastor’s Seminary (CPS), and in his final year at the London Reformed Baptist Seminary (LRBS). He currently serves as the coordinator of GTS Director/Student Dean.",
  }
];

export { boardMembers, faculty };