import React from "react";
import { BigText, Paragraph, SectionHeaderText } from "../atoms";
import { SectionWrapper } from "../layout/SectionWrapper";
import header from "../assets/individual-resource-header.png";
import responsiveHeader from "../assets/resource-2.png";
import { ResourceCard } from "../layout/Cards.jsx";
import resources from "../data/resources.js";
import leftArrow from "../assets/white-left-arrow.svg";
import rightArrow from "../assets/white-right-arrow.svg";
import { Link } from "react-router-dom";

const IndividualResources = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div style={{ color: "#3F4648" }}>
      <div className="flex">
        <div className="hidden lg:block bg-blue h-80 w-2"></div>
        <SectionWrapper>
          <Paragraph
            text={
              <Link onClick={scrollToTop} to="/resources">
                Back to Resources
              </Link>
            }
            color="#AA8B55"
          />
          <BigText
            text={
              <p>
                10 Benefits All New Covenant <br /> Members Enjoy
              </p>
            }
            className="my-8"
          />
          <div className="flex">
            <Paragraph text={<p>Published on </p>} className="mr-1" />
            <Paragraph text={<p>27 Mar 2019</p>} color="#AA8B55" />
          </div>
          <Paragraph
            text={
              <p>
                Lorem Ipsum is simply dummy text of the print ing and
                typesetting industry. Lorem Ipsum has been the industry’s
                standard dummy text ever since the 1500s, when an utype specimen
                book It has survive dries. Contrary to popular belief, Lorem
                Ipsum is not simply random text. It has roots in a piece of
                classical Latin literature from 45 BC, making it over 2000 years
                old. Richard McClintock, a Latin professor at Hampden-Sydney
                College in Virginia, looked up one of the more obscure Latin
                words, consectetur, from a Lorem Ipsum passage.
              </p>
            }
            className="mt-8"
          />
        </SectionWrapper>
      </div>
      
      <picture>
        <source media="(min-width:768px)" srcSet={header}/>
        <img src={responsiveHeader} alt="" />
      </picture>

      <SectionWrapper>
        <Paragraph
          text={
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of
              the more obscure Latin words, consectetur, from a Lorem Ipsum
              passage, and going through the cites of the word in classical
              literature, discovered the undoubtable source. Lorem Ipsum comes
              from sections and 1.10.33 of “de Finibus Bonorum et Malorum” (The
              Extremes of Good and Evil) by Cicero, written in 45 BC. Contrary
              to popular belief, Lorem Ipsum is not simply random text. It has
              roots in a piece of classical Latin literature from 45 BC, making
              it over 2000 years old. Richard McClintock, a Latin professor at
              Hampden-Sydney College in Virginia, looked up one of the more
              obscure Latin words, consectetur, from a Lorem Ipsum passage, and
              going through the cites of the word in classical literature,
              discovered the undoubtable source. Lorem Ipsum comes from sections
              and 1.10.33 of “de Finibus Bonorum et Malorum” (The Extremes of
              Good and Evil) by Cicero, written in 45 BC.
            </p>
          }
        />

        <div className="mt-20 mb-8 flex justify-between items-center">
          <SectionHeaderText text={<p>Related Resources</p>} />
          <div className="flex">
            <div className="bg-brown p-4 mr-2">
              <img src={leftArrow} className="w-6 h-6" alt="" />
            </div>
            <button className="bg-brown p-4">
              <img src={rightArrow} className="w-6 h-6" alt="" />
            </button>
          </div>
        </div>
        <div>
          <div className="grid lg:grid-cols-3 gap-12">
            {resources.slice(0, 3).map((resource) => {
              return (
                <ResourceCard
                  key={resource.id}
                  date={resource.date}
                  img={resource.imgURL}
                  title={resource.title}
                  body={resource.body}
                />
              );
            })}
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export { IndividualResources };
