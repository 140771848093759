import React from "react";
import { Input } from "../atoms";
import { TextArea } from "../atoms/TextArea";
import { SectionWrapper } from "../layout/SectionWrapper";
// 6.6317467,3.3581204,
const Contact = () => {
  return (
    <div>
      <SectionWrapper>
        <div className="">
          <p className="text-brown font-bold md:font-black text-2xl md:text-xl">
            General Inquires
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-between font-black text-base mt-4">
          <p className="text-2xl md:text-sm font-black md:font-bold text-blue mb-6 md:mb-0">
            John Doe dome address, <br /> Yaba, Lagos, Nigeria
          </p>
          <p className="text-2xl md:text-sm font-black md:font-bold text-blue mb-6 md:mb-0">
            800-944-MBTS (6287) <br /> 816-414-3700
          </p>
          <p className="text-2xl md:text-sm font-black md:font-bold text-blue mb-6 md:mb-0">
            info@example.com
          </p>
        </div>

        <form className="mt-12">
          <div className="md:flex justify-between w-full">
            <Input
              size="lg"
              placeholder="First Name"
              type="text"
              className=" md:mr-6 mb-4 md:mb-0"
            />
            <Input size="lg" placeholder="Last Name" type="text" />
          </div>
          <div className="md:flex items-end mt-8">
            <Input
              size="lg"
              placeholder="Email"
              type="email"
              className="md:mr-6 mb-4 md:mb-0"
            />
            <Input size="lg" placeholder="Phone Number" type="text" />
          </div>
          <TextArea placeholder="Message" />

          <div className="flex justify-center mt-6">
            <button className=" py-4 px-8 text-white text-xl md:text-sm font-bold text-center bg-brown">
              Submit Inquiry
            </button>
          </div>
        </form>
      </SectionWrapper>

      <div className="">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3963.122159609807!2d3.3581204!3d6.6317467!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b93956498cc3b%3A0xcdd582e003260d04!2s451%20Oluwadamilola%20Fasade%20St%2C%20Isheri%2C%20Ikeja!5e0!3m2!1sen!2sng!4v1621710657164!5m2!1sen!2sng"
          height="350"
          title="Office Location"
          style={{ width: "100%" }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export { Contact };
