export const SchemeDetails = [
  {
    id: "1",
    courseTitle: "Apologetics",
    courseDetails: [
      {
        id: "1",
        title: "AP 301. Cults and World Religions",
        note: "Presupposing Christianity to be the only true religion, this course examines the main tenets of the preeminent false religions of the world. (3 semester units)."
      },
      {
        id: "2",
        title: "AP 401. Presuppositional Apologetics",
        note: "An introductory course that includes the exposition of critical apologetical texts and surveys the main tenets of a presuppositional approach to apologetics. Dr. Paul Nelson is the author of the textbook, Presuppositionalism: A Biblical Approach to Apologetics. (3 semester units)."
      }
    ],
  },
  {
    id: "2",
    courseTitle: "Biblical Studies",
    courseDetails: [
      {
        id: "1",
        title: "PBIB 101. How to Study the Bible",
        note: "This work, by R. A. Torrey is designed to help the student learn the major concepts and principles of bible study. (3 semester units)",
      },
      {
        id: "2",
        title: "BIB 102. Introduction to the Scriptures",
        note: "This course utilizes a classic work by W. Graham Scroggie, Know Your Bible. This invaluable reference provides a brief introduction to all the books of the Bible. (3 semester units)",
      },
      {
        id: "3",
        title: "BIB 201. Biblical Hermeneutics I",
        note: "A study of the issues, history, and principles of biblical interpretation. The text is Dr. W. R. Downing’s An Introduction to Biblical Hermeneutics. Part I. (3 semester units)",
      },
      {
        id: "4",
        title: "BIB 202. Biblical Hermeneutics II",
        note: "A study of the issue, history, and principles of biblical interpretation. The text is Dr. W. R. Downing’s An Introduction to Biblical Hermeneutics. Part II. (3 semester units)",
      },
      {
        id: "5",
        title: "BIB 203. Old Testament Survey I",
        note: "A survey of Genesis through the Song of Solomon, giving a short introduction, analysis, and practical observations for each book by Dr. W. R. Downing. (3 semester units)",
      },
      {
        id: "6",
        title: "BIB 204. Old Testament Survey II",
        note: "A survey of Isaiah through the Intertestamental era- giving a short introduction, analysis, and practical observations for each book by Dr. W. R. Downing. (3 semester units)",
      },
      {
        id: "7",
        title: "BIB 205. New Testament Survey I",
        note: "A survey of Matthew through Acts, giving a short introduction, analysis, and practical observations for each book authored Dr. W. R. Downing. (3 semester units)",
      },
      {
        id: "8",
        title: "BIB 206. New Testament Survey II",
        note: "A survey of the life and epistles of the Apostle Paul, giving a short introduction, analysis, and practical observations for each book authored by Dr. W. R. Downing. (3 semester units)",
      },
      {
        id: "9",
        title: "BIB 301. New Testament Survey III",
        note: "A survey of the General Epistles and Revelation, giving a short introduction, analysis, and practical observations for each book by Dr. W. R. Downing. (3 semester units).",
      },
    ],
  },
  {
    id: "3",
    courseTitle: "Biblical Languages",
    courseDetails: [
      {
        id: "1",
        title: "NTG 401. Elementary Greek",
        note: "This course uses Dr. W. R. Downing’s Studies in New Testament Greek as the textbook. It covers the alphabet and basics of Koine Greek vocabulary and grammar. (3 semester units)."
      }
    ],
  },
  {
    id: "4",
    courseTitle: "Church History",
    courseDetails: [
      {
        id: "1",
        title: "CH 101. Church History I",
        note: "This course consists of Parts 1 and 2 of Dr. Stanford E. Murrell’s A Glorious Institution: The Church in History, which traces the history of the early church to the outbreak of the Reformation. (3 semester units)."
      },
      {
        id: "2",
        title: "CH 201. Church History II",
        note: "This course consists of Parts 3 and 4 of Dr. Stanford E. Murrell’s A Glorious Institution: The Church in History, which tells the story of the church from the days of the Reformation to the modern era. (3 semester units)."
      },
      {
        id: "3",
        title: "CH 302. Life of George Muller",
        note: "The text for this course is A. T. Pierson’s classic biography on George Muller of Bristol. This course studies his life, faith, and utter dependence upon prayer. (3 semester units)."
      }
    ],
  },
  {
    id: "5",
    courseTitle: "Revival and Missionary Studies",
    courseDetails: [
      {
        id: "1",
        title: "EM 101. New Testament Missionary",
        note: "Not everyone has the special calling, burden or spiritual gifts to be a missionary or Christian leader. It is God the Holy Spirit who assigns to individuals their spiritual gifts and who administers the call to specific services. No one can take this honor upon himself. However, those who desire the office of a Christian leader will find valuable principles and guiding concepts in this course. (3 semester units)."
      },
      {
        id: "2",
        title: "EM 301. History of Revival",
        note: "Dr. W. R. Downing outlines the principles of revival and surveys the great revivals of history. (3 semester units)."
      }
    ],
  },
  {
    id: "6",
    courseTitle: "New Testament Exposition",
    courseDetails: [
      {
        id: "1",
        title: "NTE 201. The Life of Christ",
        note: "A study of the pre-existence, earthly live and ministry, resurrection and post resurrection appearances of the Lord Jesus Christ. (3 semester units)."
      },
      {
        id: "2",
        title: "NTE 401. Exposition of Romans I",
        note: "A practical exposition of Romans written by Dr. Stanford E. Murrell. Part I. (3 semester units)."
      },
      { 
        id: "3",
        title: "NTE 402. Exposition of Romans II",
        note: "A practical exposition of Romans written by Dr. Stanford E. Murrell. Part II. (3 semester units)."
      }
    ],
  },
  {
    id: "7",
    courseTitle: "Pastoral Theology",
    courseDetails: [
      {
        id: "1",
        title: "PT 101. Holiness",
        note: "This influential work by J.C. Ryle is a study in the believer’s sanctification. It covers the nature, hinderances, difficulties and roots of holiness. (3 semester units)."
      },
      {
        id: "2",
        title: "PT 301. The Theology of Prayer I",
        note: "This course constitutes part one of The Complete Works of E.M. Bounds on Prayer. The textbook covers the power, purpose, necessity and principles of prayer. Part I. (3 semester units)."
      },
      {
        id: "3",
        title: "PT 302. The Theology of Prayer II",
        note: "This course is part two of the Theology of Prayer. It continues with The Complete Works of E.M. Bounds on Prayer as textbook and further explores the power, purpose, necessity and principles of prayer. Part II. (3 semester units)."
      },
      {
        id: "4",
        title: "PT 401. Pastoral Theology",
        note: "This course is based upon Charles Jefferson’s The Minister as Shepherd. A pastoral ministry is not something that can be learned from books, as valuable as they are. The pastoral heart can only be formed in real life situations. However, what a formal study of pastoral ministry can provide is a theological and philosophical framework that will guide behavior. The man who would be a minister of the gospel and not a hireling must be like the Master who said of Himself, “I am the Good Shepherd” (John 10:11, 14) (3 semester units)."
      },
      {
        id: "5",
        title: "PT 403. Evangelism",
        note: "This course is based upon Ryan Denton’s book on evangelism entitled, Even If None: Reclaiming Reformed Evangelism. The impropriety of various modern methods of evangelism are exposed. Instruction and encouragement are given to those who desire to be biblical in their approach to evangelism (3 semester units)."
      }
    ],
  },
  {
    id: "9",
    courseTitle: "Theology",
    courseDetails: [
      {
        id: "1",
        title: "TH 101. Bible Doctrine I",
        note: "This course by Dr. W. R. Downing offers an introductory study of bible doctrine in the form of a catechism with discussion and commentary to each question. Part I. (3 semester units)"
      },
      {
        id: "2",
        title: "TH 102. Bible Doctrine II",
        note: "This course by Dr. W. R. Downing offers an introductory study of bible doctrine in the form of a catechism with discussion and commentary to each question. Part II. (3 semester units)."
      },
      {
        id: "3",
        title: "TH 103. The Sovereignty of God",
        note: "A notable work by Arthur W. Pink that defines the nature of God’s sovereignty and discusses its relation to creation, government, salvation, reprobation, operation, human will, human responsibility and prayer. (3 semester units)."
      },
      {
        id: "4",
        title: "TH 401. Theological Propaedeutic I",
        note: "An introductory, encyclopedic study of the science of theology intended to enable the student to think theologically, consistently and in a non-contradictory manner. Dr. W. R. Downing is the author. Part I. (3 semester units)."
      },
      {
        id: "5",
        title: "TH 402. Theological Propaedeutic II",
        note: "An introductory, encyclopedic study of the science of theology intended to enable the student to think theologically, consistently and in a non-contradictory manner. Dr. W. R. Downing is the author. Part II. (3 semester units)."
      }
    ]
  }
];
