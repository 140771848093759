import React, { useState } from "react";

const Accordion = ({ title, courseDetails }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div>
      <div
        onClick={() => setIsActive(!isActive)}
        className="flex justify-between items-center border-b border-black mb-8 md:mb-0"
      >
        <p className="text-brown text-2xl md:text-xl font-bold pb-4">{title}</p>
        <div
          className=""
          style={
            {
              // width: "36px",
              // height: "36px",
              // padding: "8px",
              // // border: 2px solid #666;
              // textAlign: "center",
              // borderRadius: "50%",
              // width: "100px",
            }
          }
        >
          {isActive ? (
            <p className="text-2xl cursor-pointer text-white bg-black p-2 ">
              -
            </p>
          ) : (
            <p className="text-2xl cursor-pointer text-white bg-brown p-2">+</p>
          )}
        </div>
      </div>
      {isActive &&
        courseDetails?.map((singleCourse) => {
          return (
            <div key={singleCourse.title} className="">
              <p className="text-xl md:textont-base font-medium py-4">
                {singleCourse.title}
              </p>
              <p className="text-xl md:text-lg text-blue">
                {singleCourse.note}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default Accordion;

// {course.courseDetails.map((scheme) => {
//     return (
//       <div className="mt-6">
//         <p className="font-base font-medium">{scheme.title}</p>
//         <p className="text-sm text-blue">{scheme.note}</p>
//       </div>
//     );
//   })}
