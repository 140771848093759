export const board = [
  {
    id: "1",
    name: "William R. Downing,",
    qualification: "Th.M., D.D., Ph.D., President",
  },
  {
    id: "2",
    name: "Joseph Jacowitz",
    qualification: "B.Sc., Vice President",
  },
  {
    id: "3",
    name: "Paul S. Nelson,",
    qualification: "Th.M., Ph.D., Academic Dean",
  },
  {
    id: "4",
    name: "Edward Dalcour",
    qualification: "M.Apol., Ph.D., Administrator",
  },
  {
    id: "1",
    name: "Arthur Mellon",
    qualification: "Th.D., Administrator",
  },
];
