import { Paragraph } from "../atoms/Paragraph.jsx";
import arrowRight from "../assets/arrowRight.svg";
import { Link } from "react-router-dom";

const HomeCard = (props) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="shadow-md hover:shadow-2xl hover:scale-50 duration-300">
      <div className="">
        <img
          src={props.img}
          className="w-full cursor-pointer home-card-image ease-linear"
          alt="card"
        />
      </div>
      <div className="bg-brown w-28 h-2 relative left-6"></div>
      <div className="p-6 flex flex-col" style={{ flex: "1" }}>
        <h5 className="font-bold text-base text-blue">{props.title}</h5>
        <p>{props.details}</p>
        <p className="my-4">{props.units}</p>
        <div className="flex">
          <Link
            onClick={scrollToTop}
            className="text-brown font-extrabold justify-end"
            to="/academic/degree"
          >
            More Information
          </Link>
          <img src={arrowRight} alt="" />
        </div>
      </div>
    </div>
  );
};

const MemberCard = (props) => {
  return (

    <div className="shadow-md hover:shadow-2xl hover:scale-50 duration-300 relative">
      <img className="w-full object-cover max-h-60" src={props.img} alt="" />
      <div className="bg-brown h-3 md:h-1 w-36 md:w-20 relative left-4 mb-10 md:mb-0"></div>
      <div className="p-5">
        <div className="mb-10">
          <h5 className="text-brown text-xl md:text-base lg:text-xl font-semibold mb-2">
            {props.name}
          </h5>
        </div>
        {/* <Paragraph className="" text={props.info} /> */}
        <details open={props.isOpen}>
          <summary
            className="text-brown">
            View Info
          </summary>
          <p className="lg:shadow-md mt-6 lg:p-5 bg-white relative lg:absolute left-0 z-10">
            {props.info}
          </p>
        </details>
      </div>
    </div>
  );
};

const ResourceCard = (props) => {
  return (
    <div className="shadow-md hover:shadow-2xl hover:scale-50 duration-300">
      <img src={props.img} alt="" />
      <div className="p-5">
        <Paragraph
          className="text-lg font-semibold mb-2"
          text={props.title}
          color="#AA8B55"
        />{" "}
        <div className="bg-brown h-1 w-20"></div>
        <Paragraph className="my-3" text={props.body} color="#3F4648" />
        <Link
          to="/individual Resources"
          className="text-brown flex"
          href={props.link}
        >
          Learn More <img src={arrowRight} alt="" />
        </Link>
      </div>
    </div>
  );
};

const EventCard = (props) => {
  return (
    <div className="shadow-md hover:shadow-2xl hover:scale-50 duration-300 mb-6">
      <div className="bg-brown h-1 w-16 ml-4"></div>
      <div className="p-5">
        <h5 className="text-brown text-xl font-semibold mb-2">{props.title}</h5>
        <p>{props.date}</p>
      </div>
    </div>
  );
};

export { HomeCard, MemberCard, ResourceCard, EventCard };
