import { Link } from "react-router-dom";

import { SidebarData } from "../data/SidebarData";
import SubMenuTwo from "../templates/SubMenuTwo";
import facebook from "../assets/facebook.svg";
import youtube from "../assets/youtube.svg";
import instagram from "../assets/instagram.svg";

const Sidebar = () => {
  return (
    <div className="" style={{ height: "100%" }}>
      <div
        className={`flex flex-col -mt-4 bg-white w-screen md:hidden shadow-xl pt-14 fixed`}
        style={{
          height: "100%",
        }}
      >
        {SidebarData.map((item, index) => {
          return <SubMenuTwo item={item} key={index} />;
        })}

        <section className="mt-auto">
          <div className="flex justify-center mb-10 mr-20">
            <div className="flex" >
              <a
                href="https://www.facebook.com/GTSeminary"
                className="pointer mr-4"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/GTSeminary/"
                className="pointer mr-4"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="instagram" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCoL7aikB4CXrKg7ooTXwi1Q"
                className="pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtube} alt="youtube" />
              </a>
            </div>
          </div>
          <div className="">
            <ul className="flex pl-4 w-3/4 justify-evenly items-center py-6 text-base font-bold text-white bg-brown">
              <Link to="contact us">
                <li className="">Contact Us</li>
              </Link>
              {/* <li className="">Inquire</li>
              <Link to="/support">
                <li className="">Support</li>
              </Link> */}
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Sidebar;
