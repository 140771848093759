import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
import harmburger from "../assets/harmburger.svg";
import closeButton from "../assets/closeButton.svg";
import facebook from "../assets/facebook.svg";
import youtube from "../assets/youtube.svg";
import instagram from "../assets/instagram.svg";
import { headerItems } from "../data/headerItem";
import Submenu from "../templates/Submenu";
import Dropdown from "../templates/Dropdown";
import Sidebar from "../templates/Sidebar";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  return (
    <div className="" id="header">
      <div className="h-full w-full hidden bg-red-500 md:block md:bg-brown text-white p-2 text-sm font-medium">
        <div className="hidden md:flex justify-between">
          <p className="ml-56 lg:ml-72">Welcome to GTS</p>
          <ul className="flex justify-end">
            <Link to="contact us">
              <li className="mr-6">Contact Us</li>
            </Link>
            {/*<li className="mr-6">Inquire</li>
             <Link to="/support">
              <li className="mr-6">Support</li>
            </Link> */}
          </ul>
        </div>
      </div>

      <section className="flex bg-red-500 mt-4 md:mt-0">
        <div className="bg-blue p-2 md:p-4 pt-8 w-24 md:ml-20 lg:ml-44 -mt-10 absolute z-10 cursor-pointer">
          <Link to="/">
            <img src={logo} alt="logo" className="w-8 ml-6 md:ml-0 md:w-full" />
          </Link>
        </div>
        {sidebar ? (
          <div
            className="absolute z-50 ml-80 cursor-pointer md:hidden"
            onClick={showSidebar}
          >
            <img src={closeButton} alt="closeButton" className="" />
          </div>
        ) : (
          <div
            className="absolute z-50 ml-80 cursor-pointer md:hidden"
            onClick={showSidebar}
          >
            <img src={harmburger} alt="harmburger" className="" />
          </div>
        )}
        {sidebar && (
          <div className="absolute w-full z-40 bg-brown ml-24">
            <Sidebar sidebar={sidebar} />
          </div>
        )}
      </section>

      <section className="bg-white mt-6">
        <div className="flex items-center py-2 shadow-2xl md:shadow-none nav-items">
          <ul className="hidden list-none md:flex md:justify-between">
            <Dropdown />
            {headerItems.map((item, index) => {
              return (
                <div key={index} className="text-blue text-base font-bold">
                  <Submenu item={item} key={index} />
                </div>
              );
            })}
          </ul>

          <div className="flex items-center justify-between ml-auto mr-10 h-5">
            <a
              href="https://www.facebook.com/GTSeminary"
              className="pointer mr-4"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/GTSeminary/"
              className="pointer mr-4"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCoL7aikB4CXrKg7ooTXwi1Q"
              className="pointer"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="youtube" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
