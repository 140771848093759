import React from "react";
import { BigText } from "../atoms/";
import { SectionWrapper } from "../layout/SectionWrapper";
import { MemberCard } from "../layout/Cards.jsx";
import { faculty } from "../data/board-members.js";

const Members = () => {
  return (
    <SectionWrapper>
      {/* <BigText
        text={<p>Board Members</p>}
        color="#3F4648"
        className="text-center my-20"
      />

      <div className="grid md:grid-cols-3 gap-12">
        {boardMembers.map((member) => {
          return (
            <MemberCard
              key={member.name}
              img={member.imgURL}
              name={member.name}
              info={member.info}
              link={member.link}
            />
          );
        })}
      </div> */}

      <BigText
        text={<p>Faculty</p>}
        color="#3F4648"
        className="text-center my-20"
      />

      <div className="grid lg:grid-cols-3 gap-12 mb-40">
        {faculty.map((member) => {
          return (
            <MemberCard
              key={member.name}
              img={member.imgURL}
              name={member.name}
              info={member.info}
            />
          );
        })}
      </div>
    </SectionWrapper>
  );
};

export { Members };
