import React from "react";

const Paragraph = ({ text, color, className }) => {
  return (
    <div>
      <p
        className={`text-xl md:text-lg leading-8 md:leading-6 ${className}`}
        style={{ color: color }}
      >
        {text}
      </p>
    </div>
  );
};

export { Paragraph };
