import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";
import {
  About,
  Admission,
  Curriculum,
  Philosophy,
  Home,
  Outreach,
  Members,
  Resources,
  Doctrine,
  Contact,
  Support,
  Partnership,
  IndividualOutreach,
  IndividualResources,
} from "./pages";
import Header from "../src/layout/Header";
import Footer from "../src/layout/Footer";
import Banner from "./organisms/Banner";

function App() {
  return (
    <div className="overflow-hidden">
      <Router>
        <Header />
        <Route
          path="/"
          render={(props) => props.location.pathname !== "/" && <Banner />}
        />
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/about/philosophy" exact component={Philosophy} />
        <Route path="/admission/Admission policy" exact component={Admission} />
        <Route path="/academic/degree" exact component={Curriculum} />
        <Route path="/Grace Campus Outreach" exact component={Outreach} />
        <Route path="/Organizational Structure" exact component={Members} />
        <Route path="/resources" exact component={Resources} />
        <Route path="/doctrinal Statement" exact component={Doctrine} />
        <Route path="/contact us" exact component={Contact} />
        <Route path="/support" exact component={Support} />
        <Route path="/partnership" exact component={Partnership} />
        <Route
          path="/individual Outreach"
          exact
          component={IndividualOutreach}
        />
        <Route
          path="/individual Resources"
          exact
          component={IndividualResources}
        />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
