import React from "react";
import { SectionWrapper } from "../layout/SectionWrapper";
import OutreachOne from "../templates/outReach/OutreachOne";
import OutreachTwo from "../templates/outReach/OutreachTwo";
import OutreachThree from "../templates/outReach/OutreachThree";
import OutreachFour from "../templates/outReach/OutreachFour";
import elder from "../assets/elder-Kapambwe.jpeg";
import gts_spring from "../assets/gts_spring.jpeg";
import meeting from "../assets/ddd.jpeg";
import gts from "../assets/gts-out.png";

const Outreach = () => {
  return (
    <SectionWrapper>
      <OutreachFour
        image={gts_spring}
        title="Spring Physical Classes"
        mm="Oct"
        dd="17"
        yy="2022"
        description="Please pray for the students and faculty that God’s Spirit will teach and guide us into all truth, as we behold our God in the face of Christ. 
       
        If you will like to join us, send us a DM today. 
        
        For HIS glory alone!
        
        #seminary #bible #hermeneutics #theology #reformed #nigeria #lagos #study #pastor #distancelearning #free"
      />
      <OutreachThree
        image={gts}
        title="Physical Classes"
        mm="Jun"
        dd="14"
        yy="2022"
        description="Please pray for the students and faculty that God’s Spirit will teach and guide us into all truth, as we behold our God in the face of Christ. 
       
       If you will like to join us, send us a DM today. 
       
       For HIS glory alone!
       
       #seminary #bible #hermeneutics #theology #reformed #nigeria #lagos #study #pastor #distancelearning #free"
      />
      <OutreachOne
        image={elder}
        title="GTS Seminar with  Elder Kapambwe Nsenduluka"
        mm="Dec"
        dd="5"
        yy="2021"
        description="Grace & Truth Theological Seminary (GTS) Seminar with  Elder Kapambwe Nsenduluka, Missions Director Kwabata Baptist Church, Lusaka, Zambia."
      />
      <OutreachTwo
        image={meeting}
        title="Grace Campus Outreach"
        mm="Jul"
        dd="11"
        yy="2021"
        description="Grace Campus Outreach, a campus outreach of GTS, held its first teaching meeting at “Trinity University” in Lagos today; 11th July, 2021. We thank God for blessing the gathering. Please continue to pray for the students and the faculty of the school."
      />
    </SectionWrapper>
  );
};

export { Outreach };
