const chevronDownArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    width="1rem"
  >
    <url
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 9l-7 7-7-7"
    />
  </svg>
);
const chevronUpArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    width="1rem"
  >
    <url
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 15l7-7 7 7"
    />
  </svg>
);

export const SidebarData = [
  {
    name: "About",
    url: "/about",
    iconClosed: chevronDownArrow,
    iconOpened: chevronUpArrow,
    subHeading: [
      {
        name: "Introduction & Purpose",
        url: "/about/#introduction",
      },
      {
        name: "Mission Statement",
        url: "/about/#missionStatement ",
      },
      // {
      //   name: "Philosophy of Education",
      //   url: "/about/philosophy",
      // },
    ],
  },
  {
    name: "Admission",
    url: "/admission/Admission policy",
    iconClosed: chevronDownArrow,
    iconOpened: chevronUpArrow,
    subHeading: [
      {
        name: "Admission policy",
        url: "/admission/Admission policy/#admissionPolicy",
      },
      // {
      //   name: "Study guide",
      //   url: "/admission/study",
      // },
      {
        name: "Apply Now",
        url: "/admission/Admission policy/#applyNow",
      },
    ],
  },
  {
    name: "Academic",
    url: "/academic/degree",
    iconClosed: chevronDownArrow,
    iconOpened: chevronUpArrow,
    subHeading: [
      {
        name: "Degrees & Curriculum ",
        url: "/academic/degree/#degreesAndCurriculum",
      },
      {
        name: "Course Description",
        url: "/academic/degree/#courseDescription",
      },
    ],
  },
  // {
  //   name: "Resources",
  //   url: "/resources",
  // },
  {
    name: "Outreach",
    url: "/Grace Campus Outreach",
  },
];
