import React from "react";

const TextArea = ({ label, placeholder, id, value, error, onInputChange }) => {
  return (
    <div className="my-5 text-sm w-full">
      <label htmlFor={id} className="block text-black">
        {label}
      </label>
      <textarea
        name=""
        id={id}
        cols="30"
        rows="6"
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
        className="rounded-sm placeholder-gray-400 font-bold text-lightBlue text-xl border border-gray-300 w-full"
      />
      <span className="text-red-500">{error}</span>
    </div>
  );
};

export { TextArea };
