import card1 from '../assets/card-1.png';
import card2 from '../assets/card-2.png';
import card3 from '../assets/card-3.png';
import card4 from '../assets/card-4.png';

const cards = [
    {
        id: 1,
        imgURL: card1,
        title: "Certificate of Ministry",
        units: "30 Units"
    },
    {
        id: 2,
        imgURL: card2, 
        title: "Associate Degree of Ministry",
        units: "60 Units"
    },
    {
        id: 3,
        imgURL: card3, 
        title: "Bachelor's Degree of Theology",
        units: "120 Units"
    },
    {
        id: 4,
        imgURL: card4, 
        title: "Bachelor's Degree of Ministry",
        units: "120 Units"
    }
]

export default cards;