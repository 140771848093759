import React from "react";
import { Paragraph } from "../atoms";
import { BigText } from "../atoms";
import { ExtraBigText } from "../atoms/ExtraBigText";
import arrowRight from "./../assets/arrowRight.svg";
import { SectionWrapper } from "../layout/SectionWrapper";
import header from "../assets/header.png";
import { HomeCard } from "../layout/Cards.jsx";
import cards from "../data/cards.js";
import philosophy from "../assets/philosophy.png";
import { Link } from "react-router-dom";

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <header
        className="flex items-center"
        style={{
          backgroundImage: `url(${header})`,
          backgroundSize: "cover",
          minHeight: "85vh",
        }}
      >
        <SectionWrapper>
          <div>
            <ExtraBigText
              text={
                <p>
                  Delivering Quality Education In Theology And Pastoral Studies.
                </p>
              }
              color="#ffffff"
              className="pt-16 pb-8 max-w-3xl flex-wrap"
            />
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCLg_ywSAO9qpmiBANpu1VgG8_lktgU1S-hExGjFGrgUVYSA/viewform" target="_blank" rel="noreferrer">
              <button className="bg-white text-brown font-semibold p-4 my-10 flex">
                Get Started <img src={arrowRight} alt="" />
              </button>
            </a>
          </div>
        </SectionWrapper>
      </header>

      <SectionWrapper>
        <BigText
          text={
            <p>
              GTS is a Reformed-Baptist, (1689 Baptist <br />{" "}
              confession-believing) seminary,
            </p>
          }
          color="#3F4648"
          className="py-8 text-center"
        />
        <div className="grid lg:grid-cols-2 gap-10">
          <div className="shadow-md hover:shadow-2xl hover:scale-50 duration-300 px-4 py-6 lg:py-6 lg:px-12">
            <h4 className="text-lg font-semibold">Introduction</h4>
            <Paragraph
              text="Grace & Truth Theological Seminary (GTS) is an educational ministry instituted to train men who have been called into ministry and or preparing for pastoral ministry and women who want to be equipped with the knowledge of God."
              color="#3F4648"
              className="pb-8"
            />
            <Link className="text-brown flex" to="/about" onClick={scrollToTop}>
              More Information <img src={arrowRight} alt="" />
            </Link>
          </div>
          <div className="shadow-md hover:shadow-2xl hover:scale-50 duration-300 px-4 py-6 lg:py-6 lg:px-12 bg-gray-700">
            <h4 className="text-lg font-semibold text-white mb-4">
              Our Purpose
            </h4>
            <Paragraph
              text="Our training program has been tailored according to the Bible’s guidelines for ministry preparation, which informs our “purpose”,"
              color="#ffffff"
              className="pb-8"
            />
            <Link className="text-brown flex" to="/about" onClick={scrollToTop}>
              Mission Statement
              <img src={arrowRight} alt="" />
            </Link>
          </div>
        </div>
        <div className="mt-14 mb-10">
          <BigText
            text={<p>Our Degrees</p>}
            color="#AA8B55"
            className="text-center"
          />

          <BigText
            text={<p>We Have Something For Everyone</p>}
            color="#3F4648"
            className="text-center"
          />
        </div>

        {/* Cards */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10 mb-20">
          {cards.map((card) => {
            return (
              <HomeCard
                key={card.id}
                img={card.imgURL}
                title={card.title}
                units={card.units}
              />
            );
          })}
        </div>
        <div className="mb-24 flex flex-col lg:flex-row justify-center items-center lg:items-stretch">
          <div className="z-0 md:w-full relative lg:left-10">
            <img className="w-full shadow-md hover:shadow-2xl hover:scale-50 duration-300" src={philosophy} alt="Boy holding book" />
          </div>
          <div className="z-10 w-72 md:w-2/3 bg-white relative bottom-16 lg:w-full lg:right-10 lg:top-16 shadow-md hover:shadow-2xl hover:scale-50 duration-300">
            <div className="text-center flex justify-center">
              <h3 className="bg-brown text-white text-2xl font-semibold p-2 lg:px-5">
                Philosophy of Education
              </h3>
            </div>
            <div className="py-8 px-4 lg:p-8">
              <p className="text-center md:text-left lg:text-xl">
                GTS adopts the philosophy of education of GBU, which is based
                upon a Christian theistic world-and-life view, an inclusive
                philosophy of life that derives consistently from the
                scriptures.
              </p>
              <div className="text-center mb-4 mt-8">
                <a className="text-brown font-semibold" href="/">
                  More Information
                </a>
              </div>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export { Home };
