import React from "react";

const BigText = ({ text, color, className }) => {
  return (
    <div>
      <p
        className={`text-4xl md:text-3xl font-bold capitalize ${className}`}
        style={{ color: color }}
      >
        {text}
      </p>
    </div>
  );
};

export { BigText };
